<template>
    <div>
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.push('/')">
            <template #left class="left">
                <van-icon name="wap-home-o" size="22" />
            </template>
            <template #title>
                <span class="title" style="font-size:17px;font-weight:500;">免费课程</span>
            </template>
        </van-nav-bar>

        <img class="warp" src="@/assets/all_Icon/drawable-xhdpi/new-user-header-new.png">
        <van-tabs v-model="activeName" @click='tabClick' @change="tabClick" color="#2470F4" swipeable animated>

            <van-tab v-for="val in tabList" :title="val.name" :name="val.id" :key="val.id">
                <div class="mylive_box" v-if="list.length > 0">
                    <div class="list">
                        <div class="item" v-for="(course, key) in list" :key="key" @click="checked(course)">
                            <div class="left">
                                <img :src="course.coverPath" class="img" />
                            </div>
                            <div class="right">
                                <div class="title">{{ course.courseName }}</div>
                                <div class="active">
                                    <van-icon v-if="course.coursePackageId===checkItem.coursePackageId" name="success" />
                                    <div class="unActive" v-else>
                                        <div class="unActive_in"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <van-empty v-else class="custom-image" :image="url" description="暂无课程" />
            </van-tab>
            
        </van-tabs>

        <div class="receive"><img @click="receive" src="@/assets/all_Icon/drawable-xhdpi/new-user-draw-btn.png"></div>
    </div>
</template>

<script>
import { getTypeList, getPackageList, setReceive } from "@/api/login.js";

export default {
    name: "drainage",
    data() {
        return {
            activeName: "",
            tabList: [],
            list: [],
            checkItem: {},
            url: require('../../assets/all_Icon/drawable-xxhdpi/no_data.png')
        }
    },
    created() {
        this.getTypeList();
    },
    methods: {
        tabClick() {
            let params = {
                firstTypeId: this.activeName,
                isDrainage: 1
            };

            this.checkItem = {};
            this.getPackageList(params);
        },
        getTypeList() {
            getTypeList().then(res => {
                console.log(res);
                this.tabList = res.data.data;
                if (this.tabList.length) {
                    this.activeName = this.tabList[0].id;
                    let params = {
                        firstTypeId: this.tabList[0].id,
                        isDrainage: 1
                    };
                    this.getPackageList(params);
                }
            })
        },
        getPackageList(params) {
            getPackageList(params).then(res => {
                this.list = res.data.data;
            })
        },
        checked(item) {
            this.checkItem = item;
        },
        receive() {
            if( !this.checkItem.coursePackageId) return this.$toast('请先选择要领取的课程')
            let data = new FormData()
            data.append('coursePackageId',this.checkItem.coursePackageId);
            setReceive(data).then(res => {
                // console.log(res);
                if(res.data.code == 0) { this.$toast('领取成功'); this.$router.push('/') }
                
            })
        }
    }
}
</script>

<style lang="less" scoped>
.warp {
    width: 100%;
    height: 170px;
    object-fit: cover;
}
.mylive_box {
    height: calc(100vh - 280px);
    width: 100%;
    overflow-y: auto;

    .list {
        padding: 0 12px;

        .item {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 0;
            height: 72px;
            border-bottom: 1px solid #dddddd;

            .left {
                width: 109px;
                margin-right: 9px;
                border-radius: 5px; /*no*/

                .img {
                    width: 100%;
                    border-radius: 5px; /*no*/
                }
            }

            .right {
                width: calc(100% - 118px);

                .title {
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 550;
                    color: #333333;
                }
            }
        }
    }
}

.receive {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    -webkit-animation: new-user-footer-draw 2s infinite;
    -ms-animation: new-user-footer-draw 2s infinite;
    -moz-animation: new-user-footer-draw 2s infinite;
    animation: new-user-footer-draw 2s infinite;
    img {
        width: 200px;
        height: 50px;
    }
}
@-webkit-keyframes new-user-footer-draw {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.1);
    }
}
.active {
    color: green;
    font-size: 28px;
    text-align: right;
    position: relative;
}
.unActive{
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #ccc;
}
</style>